import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import UserList from "@/pages/UserList.vue";
import UserUpdatePage from "@/pages/UserUpdatePage.vue";
import InputPage from "@/pages/InputPage.vue";
import ManagerDevicePage from "@/pages/ManagerDevicePage.vue";
import AllDevicePage from "@/pages/AllDevicePage.vue";
import allDevicePage from "@/pages/AllDevicePage.vue";
import chartPage from "@/pages/ChartPage.vue";
import reportPage from "@/pages/ReportPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: chartPage,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/registerpage',
    name: 'registerpage',
    component: RegisterPage,
    props: true
  },
  {
    path: '/userlist',
    name: 'userlist',
    component: UserList,
    props: true
  },
  {
    path: '/inputDevice',
    name: 'inputDevice',
    component: InputPage,
    props: true
  },
  {
    path: '/allDevicePage',
    name: 'allDevicePage',
    component: allDevicePage,
    props: true
  },
  {
    path: '/managerDevicePage',
    name: 'ManagerDevicePage',
    component: ManagerDevicePage,
    props: true
  },
  {
    path: '/reportPage',
    name: 'reportPage',
    component: reportPage,
    props: true
  },
  {
    path: '/chartPage',
    name: 'chartPage',
    component: chartPage,
    props: true
  },
  {
    path: '/userupdate/:id',
    name: 'userupdate',
    component: UserUpdatePage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
