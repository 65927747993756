<template xmlns="http://www.w3.org/1999/html">
  <div v-if="isLoggedIn" class="app-container">
    <Toast />
    <transition name="layout-sidebar">
      <div class="sidebars" >
        <TopNavBar :isLoggedIn="isLoggedIn"/>
      </div>
    </transition>
    <div id="main_page">
      <div class="main_content">
        <div class="top_bar" :isLoggedIn="isLoggedIn" >
<!--          <div class="infor" v-if="tenCoSo">-->
<!--            <h3 style="white-space:pre">{{convertString(tenCoSo)}}</h3>-->
<!--            <p><span>Địa chỉ: {{diaChi}}</span></p>-->
<!--            <p><span style="white-space:pre">Điện thoại: {{convertString(sdt)}}</span> </p>-->
<!--          </div>-->
          <div class="user_box">
            <img src="./assets/bs2.png">
            <span>{{ fullName }}</span>
            <p><span>{{team}}</span></p>
          </div>
          <div class="bymenu">
            <i class="pi pi-list"></i>
          </div>
        </div>
        <router-view/>
      </div>

    </div>
<!--    <div id="footer" v-if="tenCoSo">-->
<!--      <div class="main_ft">-->
<!--        <p>Design by @QuocHoang</p>-->
<!--      </div>-->

<!--    </div>-->
  </div>
  <div v-else>
    <Toast />
    <LoginPage />
  </div>

</template>

<!--<style lang="scss">-->
//.app-container {
//  margin: 0 auto;
//  max-width: 1400px;
//  width: 100%;
//  height: inherit;
//  background: #F7F8F9;
//  .app-content {
//    margin: 8px;
//  }
//}
<!--</style>-->
<style lang="scss">
.layout-main {
  //padding: 10px 0 0 0;
  height: calc(100% - 70px);
  overflow-y: scroll;
  float: left;
  margin-left: 300px;
  width: calc(100% - 300px);
  font-size: 10px;
  z-index: 888;
}
.footer {
  //padding: 10px 0 0 0;
  margin-top: 10px;
  height: 70px;
  overflow-y: scroll;
  float: left;
  margin-left: 300px;
  alignment: center;
}
.layout-main-header{
  alignment: left;
}
//.footer {
//  height: 10%;
//  overflow-y: scroll;
//  float: bottom;
//  margin-left: clamp(200px, 20%, 300px);
//  width: 100%;
//}
.layout-sidebar {
  position: fixed;
  width: 300px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  transition: transform;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 700px) {
  .layout-main {
    margin-left: 100px;
    width: calc(100% - 100px);
    height: calc(100% - 40px);
  }
  .footer {
    height: 40px;
    margin-left: 100px;
    width: calc(100% - 100px);
  }
  .footerFirst {
    font-size: 12px;
  }
  .footerSecond {
    font-size: 10px;
  }
  .layout-sidebar {
    width: 100px;
  }
}
@media screen and (min-width: 701px) {
  .layout-main {
    margin-left: 200px;
    width: calc(100% - 200px);
    height: calc(100% - 40px);
  }
  .footer {
    height: 40px;
    margin-left: 200px;
    width: calc(100% - 200px);
  }
  .footerFirst {
    font-size: 13px;
  }
  .footerSecond {
    font-size: 11px;
  }
  .layout-sidebar {
    width: 200px;
  }
}

@media screen and (max-height: 900px) and (max-width: 500px) {
  .layout-main {
    margin-left: 100px;
    width: calc(100% - 100px);
    height: calc(100% - 80px);
  }
  .footer {
    height: 80px;
    margin-left: 100px;
    width: calc(100% - 100px);
  }
  .footerFirst {
    font-size: 13px;
  }
  .footerSecond {
    font-size: 11px;
  }
  .layout-sidebar {
    width: 100px;
  }
}
</style>
<script>
import TopNavBar from "@/components/TopNavBar";
import LoginPage from "@/pages/LoginPage";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  components: {TopNavBar, LoginPage},
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => !!store.state.token);
    const fullName = computed(() => store.state.fullName);
    const team = computed(() => store.state.team);
    console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission APPPPPPP: ");

    return {
      isLoggedIn,
      fullName,
      team,
    }
  },
  updated(){
    jQuery(".byhome").click(function(){
      jQuery(this).toggleClass( "hide-bar" );
      jQuery(".sidebars").toggleClass( "hide-bar" );
      jQuery("#main_page").toggleClass( "hide-bar" );
      jQuery("#footer").toggleClass( "hide-bar" );
    });
  }
}
</script>
