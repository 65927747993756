

import { computed, ref } from 'vue'
import moment from 'moment';
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {ThietBi} from "@/models/thietBi";
import {NhomThietBi} from "@/models/nhomThietBi";
import QLTSRepository from "@/services/QLTSRepository";
import {ChiTietLoaiThietBi} from "@/models/chiTietLoaiThietBi";
import {FilterMatchMode} from "primevue/api";
import {KhoaPhong} from "@/models/khoaPhong";
import {ThietBiUpdate} from "@/models/thietBiUpdate";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const emailPer = ref("");
    emailPer.value = "no";
    const thietBi = ref({} as ThietBi);
    const date = ref();
    const dateUpdate = ref();
    const nhomThietBi = ref([] as NhomThietBi[]);
    const chiTietLoaiThietBi = ref([] as ChiTietLoaiThietBi[]);
    const loaiThietBi = ref(0);
    const listThietBi = ref([] as ThietBi[]);
    const chuyen = ref(false);
    const tenThietBi = ref("");
    const thietBiChuyen = ref({} as ThietBi);
    const khoaPhong = ref([] as KhoaPhong[]);
    const thietBiUpdate = ref({} as ThietBiUpdate);
    const idNhomThietBi = ref(0);
    const loai = ref("");

    const valid = computed(()=> thietBiChuyen.value.idKhoaPhongTiepNhan);
    const validInput = computed(()=> thietBi.value.tenThietBi&&date.value&&loaiThietBi.value >0 &&loai.value.length >0);
    // const validInput = computed(()=> thietBi.value.tenThietBi&&thietBi.value.model&&thietBi.value.hangSanXuat&&thietBi.value.donViCungCap&&thietBi.value.giaTriTiepNhan);
    const validUpdate = computed(()=> thietBiUpdate.value.tenThietBi);

    register.value.permission = 'user';

    const userPermission = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Nhân viên", value: "user", param: 2},
    ]);
    const dsLoai = ref([
      {label: "Hành chính", value: "Hành chính"},
      {label: "Chuyên môn", value: "Chuyên môn"},
    ]);


    const loadNhomThietBi = () => {
        QLTSRepository.getListNhomThietBi()
                .then((response) => {
                  nhomThietBi.value = response.data;
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
    }
    loadNhomThietBi();

    const loadKhoaPhong = () => {
      QLTSRepository.getListKhoaPhong()
          .then((response) => {
            khoaPhong.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    loadKhoaPhong()

    const loadListThietBiById = (id) => {
      QLTSRepository.getListThietById(id)
          .then((response) => {
            listThietBi.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const loadChiTietLoaiThietBi = () => {
      QLTSRepository.getListChiTietLoaiThietBi(idNhomThietBi.value)
          .then((response) => {
            chiTietLoaiThietBi.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const createThietBi = () => {
      chiTietLoaiThietBi.value.forEach(x => {
        if(x.id == loaiThietBi.value){
          thietBi.value.thoiGianHaoMon = x.thoiGianHaoMon;
          thietBi.value.tyLeHaoMon = x.tyLeHaoMon;
          thietBi.value.idLoaiThietBi = x.id;
        }
      })
      thietBi.value.thoiGianTiepNhan = date.value;
      thietBi.value.hoatDong = true;
      thietBi.value.loai = loai.value;
      QLTSRepository.createThietBi(thietBi.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm thiết bị thành công',
              life: 2000
            });
            loadListThietBiById(thietBi.value.idLoaiThietBi);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const idThietBiChuyen = ref(0);
    const chuyenThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% chuyenThietBi: " + id);
      listThietBi.value.forEach(x => {
        if(x.id == id){
          tenThietBi.value = x.tenThietBi;
          idThietBiChuyen.value = x.id;
        }
      });
      chuyen.value = true;
    }

    const capnhat = ref(false);
    const capNhatThietBi = (id) => {

      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% capNhatThietBi: " + id);
      listThietBi.value.forEach(x => {
        if(x.id == id){
          thietBiUpdate.value.tenThietBi = x.tenThietBi;
          thietBiUpdate.value.thoiGianTiepNhan = x.thoiGianTiepNhan;
          thietBiUpdate.value.model = x.model;
          thietBiUpdate.value.hangSanXuat = x.hangSanXuat;
          thietBiUpdate.value.donViCungCap = x.donViCungCap;
          thietBiUpdate.value.idLoaiThietBi = x.idLoaiThietBi;
          thietBiUpdate.value.giaTriTiepNhan = x.giaTriTiepNhan;
          thietBiUpdate.value.loai = x.loai;
          thietBiUpdate.value.id = x.id;
        }
      });
      capnhat.value = true;
    }

    const thucHienChuyenThietBi = () => {
      QLTSRepository.tranferThietBi(idThietBiChuyen.value, thietBiChuyen.value.idKhoaPhongTiepNhan)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm thiết bị thành công',
              life: 2000
            });
            chuyen.value = false;
            loadListThietBiById(loaiThietBi.value);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const thucHienCapNhatThietBi = () => {
      if(dateUpdate.value != null) {
        thietBiUpdate.value.thoiGianTiepNhan = dateUpdate.value;
      }
      chiTietLoaiThietBi.value.forEach(x => {
        if(x.id == loaiThietBi.value){
          thietBiUpdate.value.thoiGianHaoMon = x.thoiGianHaoMon;
          thietBiUpdate.value.tyLeHaoMon = x.tyLeHaoMon;
          thietBiUpdate.value.idLoaiThietBi = x.id;
        }
      })
      console.log("######$$$$$$$$$$%%%%%%%%%%%%%% thucHienCapNhatThietBi time: " + JSON.stringify(dateUpdate.value));
      QLTSRepository.updateThietBi(thietBiUpdate.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Cập nhật thiết bị thành công',
              life: 2000
            });
            capnhat.value = false;
            loadListThietBiById(loaiThietBi.value);
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('DD/MM/YYYY');
    };

    const moneyValue = (x) => {
      return Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(x);
    };

    return {
      register,
      valid,
      userPermission,
      error,
      emailPer,
      thietBi,
      date,
      nhomThietBi,
      loadChiTietLoaiThietBi,
      chiTietLoaiThietBi,
      loaiThietBi,
      createThietBi,
      loadListThietBiById,
      listThietBi,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      moneyValue,
      chuyen,
      tenThietBi,
      thietBiChuyen,
      khoaPhong,
      chuyenThietBi,
      thucHienChuyenThietBi,
      validInput,
      thietBiUpdate,
      dateUpdate,
      capnhat,
      capNhatThietBi,
      idNhomThietBi,
      validUpdate,
      thucHienCapNhatThietBi,
      loai,
      dsLoai
    }
  }
}

