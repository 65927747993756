import Repository from './Repository';
import {AxiosResponse} from "axios";
import {ThietBi} from "@/models/thietBi";
import {LichSuBanGiao} from "@/models/lichSuBanGiao";
import {NhomThietBi} from "@/models/nhomThietBi";
import {ThietBiReject} from "@/models/thietBiReject";
import {ThietBiNgungHoatDong} from "@/models/thietBiNgungHoatDong";
import {ThietBiUpdate} from "@/models/thietBiUpdate";
import {BienBan} from "@/models/bienBan";

const resource = '/quanlytaisan';

export default new class{
    createThietBi(thietBi: ThietBi): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createThietBi`,thietBi);
    }
    updateThietBi(thietBiUpdate: ThietBiUpdate): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/updateThietBi`,thietBiUpdate);
    }
    getListThietBi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBi`);
    }
    deleteThietBi(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/deleteThietBi/${id}`);
    }
    confirmThietBi(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/confirmThietBi/${id}`);
    }
    tranferThietBi(idThietBi: number, idKhoaPhongNhan: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/tranferThietBi/${idThietBi}/${idKhoaPhongNhan}`);
    }
    createLichSuBanGiao(lichSuBanGiao: LichSuBanGiao): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createLichSuBanGiao`,lichSuBanGiao);
    }
    getListLichSuBanGiao(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListLichSuBanGiao`);
    }
    deleteLichSuBanGiao(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/deleteLichSuBanGiao/${id}`);
    }
    createNhomThietBi(nhomThietBi: NhomThietBi): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createNhomThietBi`, nhomThietBi);
    }
    getListNhomThietBi(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListNhomThietBi`);
    }
    deleteNhomThietBi(id: number): Promise<AxiosResponse<any>>{
        return Repository.delete<any>(`${resource}/deleteNhomThietBi/${id}`);
    }
    getListChiTietLoaiThietBi(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListChiTietLoaiThietBi/${id}`);
    }
    getListThietById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiById/${id}`);
    }
    getListKhoaPhong(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListKhoaPhong`);
    }
    getListThietBiChuyenGiao(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiChuyenGiao/${t1}/${t2}`);
    }
    getListThietBiTiepNhan(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiTiepNhan/${t1}/${t2}`);
    }
    getListLichSuBanGiaoById(id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListLichSuBanGiaoById/${id}`);
    }
    getListThietBiHetHan(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiHetHan/${t1}/${t2}`);
    }
    getListThietBiHoatDong(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiHoatDong`);
    }
    getListThietBiAll(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiAll/${t1}/${t2}`);
    }
    getListThietBiReject(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listRejectThietBi/${t1}/${t2}`);
    }
    rejectThietBi(thietBiReject: ThietBiReject): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/rejectThietBi`,thietBiReject);
    }
    getListSoLuongTiepNhanTrongNam(time: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListSoLuongTiepNhanTrongNam/${time}`);
    }
    getListSoLuongTiepNhanTrong12Thang(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListSoLuongTiepNhanTrong12Thang`);
    }
    getListSoLuongQuaHanTrong12Thang(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListSoLuongQuaHanTrong12Thang`);
    }
    getUserInfo(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getUserInfo`);
    }
    deactiveThietBi(thietBiNgungHoatDong: ThietBiNgungHoatDong): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/deactiveThietBi`, thietBiNgungHoatDong);
    }
    getListThietBiNgungHoatDong(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiNgungHoatDong/${t1}/${t2}`);
    }
    waitingForLiquidationThietBi(thietBiNgungHoatDong: ThietBiNgungHoatDong): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/waitingForLiquidationThietBi`, thietBiNgungHoatDong);
    }
    getListThietBiChoThanhLy(t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiChoThanhLy/${t1}/${t2}`);
    }
    getListThongKeTrong12Thang(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThongKeTrong12Thang`);
    }
    getListThongKeThietBiHienCo(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThongKeThietBiHienCo`);
    }
    getListThongKeTheoKhoaTrong12Thang(): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThongKeTheoKhoaTrong12Thang`);
    }
    exportBaoCao(list: ThietBi[], id: number): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/exportBaoCao/${id}`, list);
    }
    createBanGiao(bienBan: BienBan): Promise<AxiosResponse<any>>{
        return Repository.post<any>(`${resource}/createBanGiao`,bienBan);
    }
    getListThietBiTheoKhoa(id: number, t1: number, t2: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/getListThietBiTheoKhoa/${id}/${t1}/${t2}`);
    }
    createBaoCaoTheoGiaTri(price: number, kind: number, option: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/createBaoCaoTheoGiaTri/${price}/${kind}/${option}`);
    }
    createBaoCaoTongHop(year: number, khoaPhongId: number, option: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/baoCaoTongHop/${year}/${khoaPhongId}/${option}`);
    }
}
